<template>
  <div v-if="loggedIn">
    <Navigation
      @logout="logOut"
      :isSuperAdmin="isSuperAdmin"
      :me="meResult.me" />

    <section id="main">
      <router-view :me="meResult.me" :isAdmin="isAdmin" :isSuperAdmin="isSuperAdmin" />
    </section>

  </div>
  <Login v-else />
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { useClient, useQuery, defaultPlugins } from 'villus';
import { extractFiles } from 'extract-files';

import Cookies from 'js-cookie';
import Login from './components/Login.vue';
import Navigation from './components/Navigation.vue';

export default defineComponent({
  name: 'App',

  components: { Login, Navigation },

  setup() {
    const loggedIn = ref(false);
    const isAdmin = ref(false);
    const isSuperAdmin = ref(false);

    const cookiePlugin = ({ opContext }) => { opContext.credentials = 'include'; };
    const multipartPlugin = ({ operation, opContext }) => {
      const { files, clone: variables } = extractFiles({ ...((operation?.variables) || {}) });

      if (!files.size) { return; }

      if ((opContext.headers)['content-type'] === 'application/json') {
        delete (opContext.headers)['content-type'];
      }

      const body = new FormData();
      body.append('query', operation.query);

      const myFiles = {};

      const map = { ...variables };
      files.forEach((paths, file) => {
        paths.forEach((path) => {
          delete map[path];
          map[path] = path;
          myFiles[path] = file;
        });
      });

      body.append('variables', JSON.stringify(map));

      Object.keys(myFiles).forEach((key) => {
        const file = myFiles[key];
        body.append(key, file, file.name);
      });

      opContext.body = body;
    };

    const logOut = () => {
      Cookies.remove('token', { domain: process.env.VUE_APP_COOKIE_DOMAIN });
      loggedIn.value = false;
    };

    useClient({
      cachePolicy: 'network-only',
      url: process.env.VUE_APP_API_URL,
      use: [cookiePlugin, multipartPlugin, ...defaultPlugins()],
    });

    const Me = `
      query {
        me {
          id
          firstName
          lastName
          memberships {
            role
            group {
              id
              name
            }
          }
        }
      }
    `;

    const { data: meResult } = useQuery({ query: Me, cachePolicy: 'network-only' });

    watch(meResult, (newValue) => {
      loggedIn.value = true;

      const adminRoles = newValue.me.memberships.filter((m) => m.role === 'Bestuurslid');

      isAdmin.value = adminRoles.length > 0;
      isSuperAdmin.value = adminRoles.filter((m) => m.group.id === '41').length > 0;
    });

    return {
      loggedIn,
      logOut,
      meResult,
      isAdmin,
      isSuperAdmin,
    };
  },
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

a {
  color: black;
}

#main {
  margin-left: 250px;
  padding: 20px;

  h1 {
    margin-top: 0;
  }

  h2 {
    font-size: 18px;
    margin: 0 0 8px;
  }
}

body {
  margin: 0;
}

table {
  border-collapse: collapse;
  width: 100%;

  tr {
    td {
      border-bottom: black 1px solid;
      border-top: black 1px solid;
      padding: 5px 10px;

      a {
        text-decoration: none;
      }
    }
  }
}

form {
  button,
  select,
  textarea,
  input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="search"],
  input[type="hidden"],
  input[type="number"] {
    border: black 1px solid;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 16px;
    padding: 8px 10px;
    -webkit-appearance: none;
  }

  input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="hidden"],
  input[type="number"],
  select {
    min-width: 300px;
  }

  button {
    background: black;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: bold;

    &:disabled {
      background: #ddd;
    }
  }

  textarea {
    height: 250px;
    min-width: 500px;
  }

  select {
    background-image: url('data:image/svg+xml;utf8,<svg version="1.1" viewBox="0 0 57 29" xmlns="http://www.w3.org/2000/svg"><polygon transform="translate(28.5 14.5) scale(1 -1) translate(-28.5 -14.5)" points="28.5 0 57 29 0 29"/></svg>');
    background-position: center right 10px;
    background-repeat: no-repeat;
    background-size: 20px;
    padding-right: 40px;
  }

  label {
    display: block;
  }
}

.sub-navigation {
  border-bottom: black 1px solid;
  border-top: black 1px solid;
  list-style: none;
  margin-bottom: 33px;
  overflow: hidden;
  padding: 0;

  li {
    border-right: #ccc 1px solid;
    float: left;

    &:last-child {
      border: none;
      margin: 0;
      padding: 0;
    }

    a {
      color: #002244;
      padding: 5px 10px;
      background-color: white;
      display: block;
      text-decoration: none;

      &:hover {
        background-color: #ccc;
      }
    }

    .icon {
      width: 20px;
      height: 20px;
      float: left;
      margin-right: 10px;
    }
  }
}
</style>
