import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue'),
    props: true,
  },
  {
    path: '/users/archive',
    name: 'ArchivedUsers',
    component: () => import('../views/Users.vue'),
    props: true,
  },
  {
    path: '/group_users/:groupId',
    name: 'GroupUsers',
    component: () => import('../views/Users.vue'),
    props: true,
  },
  {
    path: '/users/new',
    name: 'NewUser',
    component: () => import('../views/NewUser.vue'),
  },
  {
    path: '/users/:id',
    name: 'UserDetails',
    component: () => import('../views/UserDetails.vue'),
    props: true,
  },
  {
    path: '/users/:id/edit',
    name: 'EditUser',
    component: () => import('../views/EditUser.vue'),
    props: true,
  },
  {
    path: '/groups',
    name: 'Groups',
    component: () => import('../views/Groups.vue'),
    props: true,
  },
  {
    path: '/groups/new',
    name: 'NewGroup',
    component: () => import('../views/NewGroup.vue'),
  },
  {
    path: '/groups/:id',
    name: 'GroupDetails',
    component: () => import('../views/GroupDetails.vue'),
    props: true,
  },
  {
    path: '/groups/:groupId/users/:id',
    name: 'GroupUserDetails',
    component: () => import('../views/UserDetails.vue'),
    props: true,
  },
  {
    path: '/finance',
    name: 'Finance',
    component: () => import('../views/Finance.vue'),
    redirect: '/finance/export',

    children: [
      {
        path: '/finance/export',
        name: 'CreateSepaExport',
        component: () => import('../views/CreateSepaExport.vue'),
      },
      {
        path: '/finance/declarations',
        name: 'CreateDeclaration',
        component: () => import('../views/Declaration.vue'),
      },
      {
        path: '/finance/invoices',
        name: 'Invoices',
        component: () => import('../views/Invoices.vue'),
      },
      {
        path: '/finance/invoices/new',
        name: 'CreateInvoice',
        component: () => import('../views/CreateInvoice.vue'),
      },
    ],
  },
  {
    path: '/posts',
    name: 'Posts',
    component: () => import('../views/Posts.vue'),
  },
  {
    path: '/posts/new',
    name: 'NewPost',
    component: () => import('../views/NewPost.vue'),
  },
  {
    path: '/files',
    name: 'Files',
    component: () => import('../views/Files.vue'),
  },
  {
    path: '/files/new',
    name: 'NewFile',
    component: () => import('../views/NewFile.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) { return savedPosition; }
    return { top: 0 };
  },
});

export default router;
