import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import '../node_modules/better-dom/dist/better-dom';
import '../node_modules/better-dateinput-polyfill/dist/better-dateinput-polyfill';

createApp(App)
  .use(router)
  .mount('#app');
