
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  props: {
    me: Object,
    isSuperAdmin: Boolean,
  },

  setup(props, { emit }) {
    const router = useRouter();

    router.beforeEach((to, _, next) => {
      document.querySelectorAll<HTMLAnchorElement>('nav a').forEach(($link) => {
        const rootPath = to.fullPath.split('/')[1];

        if (rootPath !== '' && $link.href.endsWith(rootPath)) {
          setTimeout((() => $link.classList.add('router-link-active')), 5);
        } else {
          $link.classList.remove('router-link-active');
        }
      });

      next();
    });

    const logOut = () => emit('logout');

    return { logOut };
  },
});
