<template>
  <nav>
    <h1><router-link :to="{ name: 'Dashboard' }">Ledenbeheer</router-link></h1>

    <ul>
      <li><router-link :to="{ name: 'Users' }">Alle leden</router-link></li>
      <li><router-link :to="{ name: 'Groups' }">Groepen</router-link></li>
      <li><router-link :to="{ name: 'Finance' }">Financiën</router-link></li>
      <li><router-link :to="{ name: 'Files' }">Documenten</router-link></li>
      <li v-if="isSuperAdmin"><router-link :to="{ name: 'Posts' }">Berichten</router-link></li>
    </ul>

    <p class="logout">
      <a @click="logOut">{{ me.firstName }} afmelden</a>
    </p>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  props: {
    me: Object,
    isSuperAdmin: Boolean,
  },

  setup(props, { emit }) {
    const router = useRouter();

    router.beforeEach((to, _, next) => {
      document.querySelectorAll<HTMLAnchorElement>('nav a').forEach(($link) => {
        const rootPath = to.fullPath.split('/')[1];

        if (rootPath !== '' && $link.href.endsWith(rootPath)) {
          setTimeout((() => $link.classList.add('router-link-active')), 5);
        } else {
          $link.classList.remove('router-link-active');
        }
      });

      next();
    });

    const logOut = () => emit('logout');

    return { logOut };
  },
});
</script>

<style lang="scss" scoped>
$green: #95c11f;

nav {
  background: $green;
  box-sizing: border-box;
  color: white;
  height: 100%;
  left: 0;
  padding: 20px;
  position: fixed;
  top: 0;
  width: 250px;

  li a {
    color: white;
    font-weight: bold;

    &.router-link-active {
      background: white;
      color: black;
    }
  }

  h1 {
    margin: 0 0 20px;

    a {
      background-color: white;
      background-image: url('../assets/kpj.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      border: white 5px solid;
      border-radius: 10px;
      display: block;
      height: 75px;
      text-indent: -9999px;
      white-space: nowrap;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li a {
    border-top: white 1px solid;
    display: block;
    padding: 5px 10px;
    text-decoration: none;
  }

  li:last-child a {
    border-bottom: white 1px solid;
  }
}

.logout {
  text-align: center;

  a {
    color: white;
    cursor: pointer;
    font-size: 13px;
  }
}
</style>
