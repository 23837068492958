<template>
  <div>
    <h1>Ledenbeheer</h1>
    <p>
      <a :href="loginUrl">Inloggen</a>
    </p>
  </div>
</template>

<style lang="scss" scoped>
div {
  text-align: center;
}

a {
  background: black;
  border-radius: 15px;
  color: white;
  display: inline-block;
  font-weight: bold;
  padding: 20px 50px;
  text-decoration: none;
}
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const loginUrl = process.env.VUE_APP_LOGIN_URL;
    return { loginUrl };
  },
});
</script>
